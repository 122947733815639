import {
	DeviceBatteryLevel,
	DeviceCompatibilityLevel,
	DeviceLicense,
	DeviceLicenseWarningDetails,
	DeviceMicState,
	DeviceModel
} from '@shure/shared/angular/data-access/system-api/models';

import { DeviceState } from './device-state.enum';
import { FirmwarePackage } from './firmware-package';
import { FirmwareUpdateProgress, FirmwareUpdateStage } from './firmware-update-progress';
import { MissingFeatureType } from './missing-feature-type';
import { NetworkInterface } from './network-interface';
import { ProxiedDevice } from './proxied-device';
import { SortedDeviceConditionCollection } from './sorted-device-condition-collection';

export interface InventoryDevice {
	id: string;
	name: string;
	model: DeviceModel;
	virtual: boolean;
	deviceState: DeviceState;
	hardwareId?: string;
	compatibility: DeviceCompatibilityLevel;
	features: {
		audioChannelCount?: {
			count: number;
		} & MissingFeatureType;
		audioNetwork?: NetworkInterface & MissingFeatureType;
		availablePackages?: {
			primaryPackages: FirmwarePackage[];
		} & MissingFeatureType;
		batteryLevel?: DeviceBatteryLevel & MissingFeatureType;
		controlNetwork?: NetworkInterface & MissingFeatureType;
		danteName?: {
			danteName: string;
		} & MissingFeatureType;
		firmware?: {
			version: string;
			valid: boolean;
			isUpToDate: boolean;
		} & MissingFeatureType;
		identify?: {
			identifying: boolean;
		} & MissingFeatureType;
		license?: DeviceLicense<DeviceLicenseWarningDetails> & MissingFeatureType;
		micStatus?: {
			status: DeviceMicState;
		} & MissingFeatureType;
		mute?: {
			muted: boolean;
		} & MissingFeatureType;
		proxiedDevices?: {
			proxiedDevices: ProxiedDevice[];
		} & MissingFeatureType;
		serialNumber?: {
			serialNumber: string;
		} & MissingFeatureType;
		updateProgress?: FirmwareUpdateProgress & MissingFeatureType;
		uptime?: {
			uptime: string;
		} & MissingFeatureType;
		room?: {
			room: {
				name: string;
			};
		} & MissingFeatureType;
		tags?: {
			tags: string[];
		} & MissingFeatureType;
	};
	conditions: SortedDeviceConditionCollection;
}

export function isDeviceUpdating(device: InventoryDevice): boolean {
	return isUpdateInProgress(device.features.updateProgress?.stage);
}

export function isUpdateInProgress(stage?: FirmwareUpdateStage): boolean {
	return (
		stage === FirmwareUpdateStage.Pending ||
		stage === FirmwareUpdateStage.DeviceReserved ||
		stage === FirmwareUpdateStage.TransferringFiles ||
		stage === FirmwareUpdateStage.PreInstallValidating ||
		stage === FirmwareUpdateStage.Installing ||
		stage === FirmwareUpdateStage.Rebooting ||
		stage === FirmwareUpdateStage.Verifying
	);
}

export function isUpdateEnded(stage?: FirmwareUpdateStage): boolean {
	return (
		stage === FirmwareUpdateStage.Complete ||
		stage === FirmwareUpdateStage.Canceled ||
		stage === FirmwareUpdateStage.Error
	);
}
