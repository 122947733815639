import { DateTime } from 'ts-luxon';

import { LicenseInfo } from '@shure/cloud/shared/models/devices';

import { DeviceLicenseInfoV3Fragment } from '../graphql/generated/cloud-sys-api';

import { mapLicenseStateFromSysApi } from './map-license-state';

export function mapLicenseInfoV3FromSysApi(licenseInfos: DeviceLicenseInfoV3Fragment[]): LicenseInfo[] {
	return licenseInfos.map((license) => ({
		count: license.count,
		expirationDate: license.expirationDate ? DateTime.fromISO(license.expirationDate) : undefined,
		featureName: license.featureName ?? undefined,
		perpetual: !!license.perpetual,
		state: mapLicenseStateFromSysApi(license.state)
	}));
}
