import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Subject, map, Observable, take, BehaviorSubject } from 'rxjs';

export interface NotificationStoreData {
	id: string;
	body?: string;
	sender: {
		firstName: string;
		lastName: string;
		email: string;
	};
	organization?: {
		id?: string;
		name?: string;
	};
	sentAt?: string;
	status: string;
	readAt?: string;
	archivedAt?: string;
	trashedAt?: string;
	subject: string;
	isRead?: boolean;
}

export interface NotificationState {
	notifications: NotificationStoreData[];
}

const initialState: NotificationState = {
	notifications: []
};

@Injectable({ providedIn: 'root' }) // This makes it singleton
export class NotificationsStore extends ComponentStore<NotificationState> {
	public selectedRowData!: NotificationStoreData;
	public triggerNotificationUpdate$ = new Subject<boolean>();

	public notificationFromWS: BehaviorSubject<NotificationStoreData | null> =
		new BehaviorSubject<NotificationStoreData | null>(null);

	// SELECTORS
	public notifications$ = this.select(
		// Filter out trashed/archived notifications
		(state) => state.notifications.filter((single) => !single.archivedAt && !single.trashedAt)
	);

	public trashedNotifications$ = this.select(
		// Filter out non-trashed notifications
		(state) => state.notifications.filter((single) => single.trashedAt)
	);

	public unreadNotificationsCount$ = this.select(
		// Filter out read/trashed/archived notifications
		(state) =>
			state.notifications.filter((single) => !single.archivedAt && !single.trashedAt && !single.readAt).length
	);

	public trashedNotificationsCount$ = this.select(
		// Filter out non-trashed notifications
		(state) => state.notifications.filter((single) => single.trashedAt).length
	);

	// UPDATERS
	public setNotifications = this.updater((state, notifications: NotificationStoreData[]) => {
		return { ...state, notifications: notifications };
	});

	public prependOrPatchNotifications = this.updater(
		(state, notificationsToAdd: Array<Pick<NotificationStoreData, 'id'> & Partial<NotificationStoreData>>) => {
			// Index existing notifications by id for quick access
			const notificationsIndex = new Map(
				state.notifications.map((notification) => [notification.id, notification])
			);

			// Prepare an array to hold new notifications
			const newNotifications: NotificationStoreData[] = [];

			// Iterate over new notifications to update existing ones or collect new ones
			for (const notification of notificationsToAdd) {
				if (notificationsIndex.has(notification.id)) {
					// If the notification exists, merge it with the new data using Object.assign
					Object.assign(<NotificationStoreData>notificationsIndex.get(notification.id), notification);
				} else {
					// If the notification is new, add it to the array of truly new notifications
					newNotifications.push(<NotificationStoreData>notification);
				}
			}

			// Prepend new notifications to the beginning of the existing notifications array
			// The order of existing notifications remains unchanged
			const updatedNotifications = [...newNotifications, ...state.notifications];

			return {
				...state,
				notifications: updatedNotifications
			};
		}
	);

	public addNotification = this.updater((state, notificationData: NotificationStoreData) => {
		return { ...state, ...{ notifications: [...state.notifications, notificationData] } };
	});

	public updateNotification = this.updater(
		(state, updatedNotification: Pick<NotificationStoreData, 'id'> & Partial<NotificationStoreData>) => {
			return {
				...state,
				...{
					notifications: state.notifications.map((notification: NotificationStoreData) =>
						notification.id === updatedNotification.id
							? Object.assign(notification, updatedNotification)
							: notification
					)
				}
			};
		}
	);

	public deleteNotification = this.updater((state, notificationId: string) => {
		return {
			...state,
			...{
				notifications: state.notifications.filter(
					(notification: NotificationStoreData) => notification.id !== notificationId
				)
			}
		};
	});

	public deleteAllTrashedNotifications = this.updater((state) => {
		return {
			...state,
			notifications: state.notifications.filter((notification) => !notification.trashedAt)
		};
	});

	constructor() {
		super(initialState);
	}

	public getFilteredNotifications(
		searchTerm: string,
		notifications: Observable<NotificationStoreData[]>
	): Observable<NotificationStoreData[]> {
		if (!searchTerm) {
			return notifications;
		}

		// @TODO This can be moved to the notification component because we can't use takeUntil here
		return notifications.pipe(
			take(1),
			map((mappedNotifications) => {
				return mappedNotifications.filter((notification) => {
					return (
						notification.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
						(notification.body && notification.body.toLowerCase().includes(searchTerm.toLowerCase())) ||
						(notification?.organization?.name &&
							notification.organization.name.toLowerCase().includes(searchTerm.toLowerCase()))
					);
				});
			})
		);

		// return notifications.subscribe((notifications) => {
		// 	return notifications.filter((notification) => {
		// 		return (
		// 			notification.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
		// 			(notification.body && notification.body.toLowerCase().includes(searchTerm.toLowerCase()))
		// 		);
		// 	});
		// });
	}

	public updateWSNotification(newNotifications: NotificationStoreData): void {
		this.notificationFromWS.next(newNotifications);
	}
}
