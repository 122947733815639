import { DeviceCompatibilityLevel } from '@shure/shared/angular/data-access/system-api/models';

import { DeviceState } from './device-state.enum';
import { PropertyPanelDevice } from './property-panel-device.model';

/**
 * Model that represents the minimum useful subset of properties shared between inventory and property
 * panel devices.
 */
export interface PartialDevice {
	id: string;
	compatibility?: DeviceCompatibilityLevel;
	deviceState?: DeviceState;
	status?: DeviceState;
	features: Partial<
		Pick<
			PropertyPanelDevice['features'],
			'availablePackages' | 'identify' | 'micStatus' | 'mute' | 'reboot' | 'rfChannels' | 'updateProgress'
		>
	>;
}

/**
 * Utility function to get the device state regardless of which actual device model is provided.
 * TODO: Rename device state fields on inventory and property panel devices so they match.
 */
export function getDeviceState(device: PartialDevice): DeviceState {
	return device.deviceState ?? device.status ?? DeviceState.Unknown;
}
